import React from 'react';
import Button from '@material-ui/core/Button';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Done from '@material-ui/icons/Done';
import ShowMoreText from 'react-show-more-text';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';

import ArrowTooltip from '@packages/components/tooltip';
import {
  commonTranslations,
  recordTranslations,
  termPeriodTranslations,
  getRiskTranslation,
  typeTranslations,
  customTranslation
} from '@packages/utils/commontranslations';
import { getLocalDateTime, getLocalTime } from '@packages/utils/date-utils';
import {
  deepCopy,
  sortItems,
  linkGroupItems,
  supportedJurisdictions,
  FLAT,
  NO_LIMIT,
  TOP_DOWN
} from '@packages/utils/common-utils';
import { encodeUrl } from '@packages/utils/query-parameters';
import processingFullfieldsList from '@packages/features/settings/components/fieldVisibility/defaultFields/processingDefaults';
import assessmentFullfieldsList from '@packages/features/settings/components/fieldVisibility/defaultFields/assessmentDefaults';
import { statusItems } from '@packages/utils/record-utils';
import styles from '@packages/ui/styles';
import errortranslations, {
  customTranslations
} from '@packages/utils/errortranslations';

import publicAssessmentFields from '../settings/components/integration/public-registry/fieldVisibility/default/assessmentDefaults';
import publicProcessingFields from '../settings/components/integration/public-registry/fieldVisibility/default/processingDefaults';
import complianceStyles from '../privacy-records/components/header/styles';
import { EUCountryList } from './legalGroundUtils';

export const numOfDataItemsMapping = {
  '-1': { min: -1, max: -1 },
  0: { min: 0, max: 100 },
  100: { min: 100, max: 1000 },
  1000: { min: 1000, max: 10000 },
  10000: { min: 10000, max: 100000 },
  100000: { min: 100000, max: 1000000 },
  1000000: { min: 1000000, max: 10000000 },
  10000000: { min: 10000000, max: 100000000 },
  100000000: { min: 100000000, max: -1 }
};

export const getChangeStatusActions = (onSubmit, onCancel) => [
  <Button onClick={onSubmit} type="submit" key="submit-success">
    {commonTranslations.Yes}
  </Button>,
  <Button key="submit-cancel" variant="text" onClick={onCancel}>
    {commonTranslations.No}
  </Button>
];

export const getSaveAndCloseAction = (onSubmit, onCancel) => [
  <Button onClick={onSubmit} type="submit" key="submit-success">
    {commonTranslations.proceed}
  </Button>,
  <Button key="submit-cancel" variant="text" onClick={onCancel}>
    {commonTranslations.Cancel}
  </Button>
];

export const getRecordLockedActions = (onSubmit, onCancel) => [
  <Button variant="text" onClick={onCancel} key="discard-changes">
    <FormattedMessage
      id="PrivacyRecords.discardChanges"
      description="Discard changes"
      defaultMessage="Discard changes"
    />
  </Button>,
  <Button onClick={onSubmit} type="submit" key="create-record">
    <FormattedMessage
      id="PrivacyRecords.createNew"
      description="Create new record"
      defaultMessage="Create new record"
    />
  </Button>
];

export const validate = (values, isDefaultLogicLayout) => {
  const errorMessage = values.recordType?.toLowerCase().includes('template')
    ? recordTranslations.templateNameRequired
    : recordTranslations.nameRequired;
  const jurisdictionErrorMessage = errortranslations.jurisdictionRequired;
  const aclErrorMessage = errortranslations.aclValidation;
  const recordNameLimit = 250;

  let errors = {};
  if (!values.name) {
    errors = {
      name: errorMessage,
      _error: errorMessage
    };
  }
  if (values.name?.length > recordNameLimit)
    errors.name = customTranslations('maxLimit', recordNameLimit);
  if (
    isDefaultLogicLayout &&
    values.jurisdictions &&
    values.jurisdictions.length === 0
  ) {
    errors = {
      ...errors,
      jurisdictions: jurisdictionErrorMessage,
      _error: jurisdictionErrorMessage
    };
  }
  if (
    values.aclOrgs &&
    values.aclOrgs.length === 0 &&
    values.aclUsers &&
    values.aclUsers.length === 0
  ) {
    errors.aclOrgs = { _error: aclErrorMessage };
    errors.aclUsers = { _error: aclErrorMessage };
  }
  return errors;
};

export const assessmentRecordTypes = [
  {
    value: 'tia_assessments',
    label: typeTranslations.transferImpactAssessment
  },
  {
    value: 'dpia_assessments',
    label: typeTranslations.dpia
  }
];

export const getStatusItems = (
  approvePermission,
  userHierarchy,
  currentStatus
) => {
  let statusOptions = [...statusItems];
  if (!approvePermission) {
    statusOptions = statusOptions.filter(
      (item) =>
        !['record_status_approved', 'record_status_disapproved'].includes(
          item.value
        )
    );
  }
  if (userHierarchy === FLAT) {
    statusOptions = statusOptions.filter(
      (item) =>
        item.value === currentStatus ||
        !['record_status_redundant', 'record_status_withdrawn'].includes(
          item.value
        )
    );
  }
  return statusOptions;
};

export const getRequestMethod = (type) => {
  switch (type) {
    case 'controllers':
      return 'ChangeControllers';
    case 'executingEntities':
      return 'ChangeExecutingEntities';
    case 'dataRecipients':
      return 'ChangeDataRecipients';
    case 'personalDataItems':
      return 'ChangePersonalDataItems';
    case 'dataSubjectCategories':
      return 'ChangeDataSubjectCategories';
    case 'processingGrounds':
      return 'ChangeProcessingGrounds';
    case 'transferGrounds':
      return 'ChangeTransferGrounds';
    default:
      return 'ChangeProperties';
  }
};

export const getDefaultRenderData = (action) => {
  const { data, property } = action;

  const renderData = {};
  renderData[property] = cloneRenderData(data, property);
  return renderData;
};

export const cloneRenderData = (renderData, property) => {
  switch (property) {
    case 'personalDataItems':
    case 'retentionTerms':
      // renderData for personalDataItems contain addlkey
      // and for rententionTerms contain key which is a react.symbol
      // which does not get copied in shallow copy
      return deepCopy(renderData);
    default:
      // shallow copy
      return JSON.parse(JSON.stringify(renderData));
  }
};

// Get notes value for items in view and diff page.
export const getNoteValue = (data) =>
  data.note && (data.note.added || data.note.new || data.note);

// Transform legal entity request item.
export const transformLegalEntities = (legalEntities) =>
  legalEntities &&
  legalEntities.map((entity) => {
    const entityItem = entity.value ? entity.value : entity;
    return {
      value: {
        id: entityItem.id,
        name: entityItem.name
      },
      note: entity.note
    };
  });

export const transformLegalGrounds = (legalGrounds) =>
  legalGrounds &&
  legalGrounds.map((ground) => {
    const legalGroundItem = ground.value ? ground.value : ground;
    return {
      ...ground,
      value: {
        id: legalGroundItem.id
      },
      note: ground.note
    };
  });

// Transform legal entity request item.
export const transformLinkedItems = (linkedItems) =>
  linkedItems &&
  linkedItems.map((item) => {
    const linkedItem = item.value ? item.value : item;
    return {
      value: {
        id: linkedItem.id,
        name: linkedItem.name
      }
    };
  });

export const transformProcessorObject = (processors, isFromTemplate) => {
  const processorsObj = { ...processors };
  // If it is not a template, delete name and country
  delete processorsObj.country;
  if (processorsObj.children) {
    processorsObj.subProcessors = processorsObj.children;
    delete processorsObj.children;
    for (let i = 0; i < processorsObj.subProcessors.length; i += 1) {
      const subProcessor = processorsObj.subProcessors[i];
      if (!subProcessor.value) {
        processorsObj.subProcessors[i] = {
          value: transformProcessorObject(subProcessor, isFromTemplate),
          decisions: subProcessor.decisions,
          findings: subProcessor.findings,
          isConsulted: subProcessor.isConsulted,
          note: subProcessor.note,
          processorRole: subProcessor.processorRole
        };
        delete processorsObj.subProcessors[i].value.change;
        delete processorsObj.subProcessors[i].value.decisions;
        delete processorsObj.subProcessors[i].value.findings;
        delete processorsObj.subProcessors[i].value.isConsulted;
        delete processorsObj.subProcessors[i].value.note;
        delete processorsObj.subProcessors[i].value.processorRole;
      }
    }
  } else if (!processorsObj.value) {
    processorsObj.subProcessors = [];
  }
  return processorsObj;
};

// Transform diff items.
export const transformDiffItems = (content) =>
  content &&
  content.map((data) => {
    const { change, item } = getDiffContent(data);
    return {
      ...item,
      change,
      value: {
        ...item.value,
        key: getContent(item.value.name).data
      }
    };
  });

/* *************** Get URL with filters ****************** */

// Get the acl users URL with filter added
export const getACLUsers = (aclUsers) => {
  let url = '';
  const arr = [];
  if (aclUsers && aclUsers.length > 0) {
    url = '/v1/users?';
    aclUsers.map((userId) => {
      arr.push(`filter=id%3D${userId.value}`);
      return arr;
    });
  }
  const values = arr.join('&');
  url = `${url}${values}`;
  // url = `${url}sortOn=id`;
  return url;
};

// Get the acl orgs URL will filter added
export const getACLOrgs = (aclOrgs) => {
  let url = '';
  const arr = [];
  if (aclOrgs && aclOrgs.length > 0) {
    url = '/v1/organisations?';
    aclOrgs.map((orgId) => {
      arr.push(`filter=id%3D${orgId.value}`);
      return arr;
    });
  }
  const values = arr.join('&');
  url = `${url}${values}`;
  // url = `${url}sortOn=id`;
  return url;
};

/* *************** Get URL with filters END**************** */

/* *************** Add Keys to item lists to render in item selector ****************** */

// Transform and add keys to legal grounds
export const addKeyToLegalGrounds = (legalGrounds) => {
  let transformedLegalGrounds = Object.assign([], legalGrounds);
  if (legalGrounds) {
    transformedLegalGrounds = legalGrounds.map((legalGround) => {
      const { change, item } = getDiffContent(legalGround);
      const { jurisdiction, lawAbbreviation, article, summary } = item.value;
      let key = `${jurisdiction.id} ${lawAbbreviation} ${article} - ${summary}`;
      if (jurisdiction.old || lawAbbreviation.old || article.old) {
        key = (
          <span>
            <div style={styles.deleted}>
              {jurisdiction.old || jurisdiction.id}{' '}
              {lawAbbreviation.old || lawAbbreviation} {article.old || article}{' '}
              - {summary.old || summary}
            </div>
            <div style={{ ...styles.added, marginTop: 15 }}>
              {jurisdiction.new || jurisdiction.id}{' '}
              {lawAbbreviation.new || lawAbbreviation} {article.new || article}{' '}
              - {summary.old || summary}
            </div>
          </span>
        );
      }

      return {
        ...item,
        change,
        value: {
          ...item.value,
          jurisdiction: item.value.jurisdiction.id,
          key,
          legalGroundContent: item.value.content
        }
      };
    });
  }
  return transformedLegalGrounds;
};

// Transform and add keys to legal entities
export const addKeyToLegalEntities = (legalEntities) => {
  let transformedLegalEntities = Object.assign([], legalEntities);
  if (legalEntities) {
    const sortedItems = sortItems(legalEntities, 'ASC', 'name');
    transformedLegalEntities = sortedItems.map((legalEntity) => {
      const { change, item } = getDiffContent(legalEntity);
      const key = getLegalEntityKey(item.value);
      return {
        ...item,
        change,
        value: {
          ...item.value,
          key
        }
      };
    });
  }
  return transformedLegalEntities;
};

// Transform and add keys to linked items
export const addKeyToLinkedItems = (linkedItems) => {
  let transformedLinkedItems = Object.assign([], linkedItems);
  if (linkedItems) {
    const sortedItems = sortItems(linkedItems, 'ASC', 'name');
    transformedLinkedItems = sortedItems.map((linkedItem) => {
      const { change, item } = getDiffContent(linkedItem);
      const modifiedItem = isType(item, 'string')
        ? item
        : {
          change,
          value: {
            id: item.value.id,
            name: item.value.name,
            key: getContent(item.value.name).data
          }
        };
      return modifiedItem;
    });
  }
  return transformedLinkedItems;
};

export const getLegalEntityKey = (item) => {
  const { name, country } = item;
  let key = `${name} (${country.id})`;
  if (name.old) {
    key = (
      <span>
        <div style={styles.deleted}>
          {name.old || name} ({country.old.id || country.id})
        </div>
        <div style={{ ...styles.added, marginTop: 15 }}>
          {name.new || name} ({country.new.id || country.id})
        </div>
      </span>
    );
  }
  return key;
};

// Returns the object with diff and item
export const getDiffContent = (content) => {
  if (isType(content, 'object')) {
    if (content.new !== undefined && content.new !== null) {
      return { change: '+', item: content.new };
    } else if (content.old !== undefined && content.old !== null) {
      return { change: '-', item: content.old };
    } else if (content.added !== undefined) {
      return { change: '+', item: content.added };
    } else if (content.deleted !== undefined) {
      return { change: '-', item: content.deleted };
    }
  }

  if (content.constructor !== Array || content.length < 1) {
    return { change: '=', item: content };
  }
  return { change: content[0], item: content[1] };
};
/* *************** Add Keys to item lists to render in item selector End ****************** */

// Get the acl user orgs
export const getUserOrg = (user) => {
  let aclOrgs = [];
  if (user && user.get('profile') && user.get('profile').get('organisations')) {
    const userOrg = user.get('profile').get('organisations').toJS();
    aclOrgs = userOrg;
  }
  return aclOrgs;
};

export const isType = (val, type) =>
  val ? val.constructor.name.toLowerCase() === type.toLowerCase() : false;

export const getContentStyle = (data, defaultStyle = {}) => {
  if (data.change === '+') {
    return { ...defaultStyle, ...styles.added };
  } else if (data.change === '-') {
    return { ...defaultStyle, ...styles.deleted };
  }

  return defaultStyle;
};
export const getShowMoreText = (data, mapper, locale, themeColor) => (
  <ShowMoreText
    lines={2}
    more={
      <span
        style={{ color: themeColor, textDecoration: 'underline white' }}
        data-elm-type="show-more-text"
      >
        <FormattedMessage
          id="ItemList.ShowMore"
          description="Show more"
          defaultMessage="Show more"
        />
      </span>
    }
    less={
      <span
        style={{ color: themeColor, textDecoration: 'underline white' }}
        data-elm-type="show-more-text"
      >
        <FormattedMessage
          id="ItemList.ShowLess"
          description="Show less"
          defaultMessage="Show less"
        />
      </span>
    }
  >
    {mapper ? mapper(data, locale) : data}
  </ShowMoreText>
);

export const getContent = (data, mapper, locale, themeColor, showMore) => {
  let style = {};

  // Handle array values with diff
  if (isType(data, 'array') && data.length > 1) {
    style = data[0] === '-' ? styles.deleted : styles.added;
    return { data: mapper ? mapper(data[1], locale) : data[1], style };
  }

  // Handle objects with diff
  if (isType(data, 'object')) {
    let content = data;
    if (data.new !== undefined || data.old !== undefined) {
      content = (
        <span>
          <span style={styles.deleted}>
            {getShowMoreText(data.old, mapper, locale, themeColor)}
          </span>
          <span style={styles.added}>
            {getShowMoreText(data.new, mapper, locale, themeColor)}
          </span>
        </span>
      );
    } else if (data.added !== undefined) {
      content = (
        <span style={styles.added}>
          {getShowMoreText(data.added, mapper, locale, themeColor)}
        </span>
      );
    } else if (data.deleted !== undefined) {
      content = (
        <span style={styles.deleted}>
          {getShowMoreText(data.deleted, mapper, locale, themeColor)}
        </span>
      );
    } else if (data.min || data.max) {
      content = (
        <span style={{ marginRight: 10 }}>
          {mapper ? mapper(data, locale) : data.min}
        </span>
      );
    }

    return { data: content, style };
  }
  return {
    data: showMore
      ? getShowMoreText(data, mapper, locale, themeColor)
      : getNonObjectContent(data, mapper, locale),
    style
  };
};

const getNonObjectContent = (data, mapper, locale) =>
  mapper ? mapper(data, locale) : data;

// Get local date mapper.
export const dateMapper = (date, locale) =>
  getLocalDateTime(date, 'DD/MM/YYYY', locale);

// Get local time mapper.
export const timeMapper = (date) => getLocalTime(date);

// Mapper for number of data subjects or personal data records.
export const numberOfDataMapper = (numOfData) => {
  switch (numOfData) {
    case -1:
    case '-1':
      return commonTranslations.unknown;
    case 0:
      return '< 100';
    case 100:
      return '100 < 1 000';
    case 1000:
      return '1 000 < 10 000';
    case 10000:
      return '10 000 < 100 000';
    case 100000:
      return '100 000 < 1 000 000';
    case 1000000:
      return '1 000 000 < 10 000 000';
    case 10000000:
      return '10 000 000 < 100 000 000';
    case 100000000:
      return '> 100 000 000';
    default:
      return numOfData;
  }
};

export const numberOfDataSubjectMapper = (numOfData) => {
  if (
    (numOfData.min === -1 && numOfData.max === -1) ||
    (numOfData.min === '-1' && numOfData.max === '-1')
  ) {
    return commonTranslations.unknown;
  } else if (numOfData.min === 0 && numOfData.max === 100) {
    return '< 100';
  } else if (numOfData.min === 100 && numOfData.max === 1000) {
    return '100 < 1 000';
  } else if (numOfData.min === 1000 && numOfData.max === 10000) {
    return '1 000 < 10 000';
  } else if (numOfData.min === 10000 && numOfData.max === 100000) {
    return '10 000 < 100 000';
  } else if (numOfData.min === 100000 && numOfData.max === 1000000) {
    return '100 000 < 1 000 000';
  } else if (numOfData.min === 1000000 && numOfData.max === 10000000) {
    return '1 000 000 < 10 000 000';
  } else if (numOfData.min === 10000000 && numOfData.max === 100000000) {
    return '10 000 000 < 100 000 000';
  } else if (numOfData.min === 100000000 && numOfData.max === -1) {
    return '> 100 000 000';
  }
  return numOfData.min;
};
// Boolean mapper
export const booleanMapper = (value) =>
  value === false ? commonTranslations.No : commonTranslations.Yes;

// Expand / Collapse UI
export const getExpandCollapseUI = (toggleHandler, expandAllsection, theme) => (
  <div
    role="presentation"
    style={styles.expandCollapseRootStyle}
    onClick={toggleHandler}
    onKeyDown={toggleHandler}
  >
    <div
      style={{
        ...styles.expandCollapseLineStyle,
        borderBottomColor: theme.palette.primary.main
      }}
    />
    <span
      style={{
        width: 'auto',
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: '18px',
        whiteSpace: 'nowrap'
      }}
    >
      <FontAwesome
        name={expandAllsection ? 'caret-up' : 'caret-down'}
        style={styles.collapseIcon}
      />
      {expandAllsection ? (
        <FormattedMessage
          id="Card.collapseAll"
          description="Collapse sections"
          defaultMessage="Collapse sections"
        />
      ) : (
        <FormattedMessage
          id="Card.expandAll"
          description="Expand sections"
          defaultMessage="Expand sections"
        />
      )}
    </span>
    <div
      style={{
        ...styles.expandCollapseLineStyle,
        borderBottomColor: theme.palette.primary.main
      }}
    />
  </div>
);

export const getGoBackLink = (prevLocation, theme, customStyle) => (
  <Link
    to={prevLocation}
    style={{
      width: '10%',
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette ? theme.palette.primary.main : '',
      ...(customStyle || '')
    }}
  >
    <FontAwesome name="angle-left" style={{ fontSize: '1em' }} />
    <span style={styles.linkTxtStyle}>{commonTranslations.goBack}</span>
  </Link>
);

export const getNameAndValue = () => {
  let value = '';

  const hashURL = decodeURIComponent(encodeUrl(window.location.hash));
  const name = hashURL.includes('name=')
    ? hashURL.substring(hashURL.lastIndexOf('name=') + 5)
    : null;

  if (hashURL.includes('value=') && name) {
    value = hashURL.slice(
      hashURL.lastIndexOf('value=') + 6,
      hashURL.lastIndexOf('&name=')
    );
  } else {
    value = hashURL.substring(hashURL.lastIndexOf('&value=') + 7);
  }

  return { name, value };
};

export const getModifiedRecordsList = (fields, recordId, records) => {
  const selectedrecords = fields.getAll ? fields.getAll() : fields;
  const filteredData = records?.filter((record) => {
    const index =
      fields && fields.length > 0
        ? record.id !== recordId &&
          selectedrecords.findIndex(
            (selectedrecord) =>
              (selectedrecord.value
                ? selectedrecord.value.id
                : selectedrecord.id) === record.id
          )
        : record.id !== recordId &&
          records.indexOf((item) => item.id !== recordId);
    return index === -1;
  });
  return filteredData;
};

export const getRetentionFormat = (retention, retentionType) => {
  let format = retention;
  if (retention !== undefined) {
    if (retentionType === 'year') {
      format = `P${retention}Y`;
    } else if (retentionType === 'month') {
      format = `P${retention}M`;
    } else if (retentionType === 'week') {
      format = `P${retention}W`;
    } else if (retentionType === 'day') {
      format = `P${retention}D`;
    } else if (retentionType === 'hour') {
      format = `PT${retention}H`;
    } else if (retentionType === 'minute') {
      format = `PT${retention}M`;
    }
  }
  return format;
};

export const transformRetentionTerm = (retentionTerm) => {
  const lastChar = retentionTerm && retentionTerm[retentionTerm.length - 1];
  const match = retentionTerm && retentionTerm.match(/P(T*)(\d+)\w/);
  let term = '';
  switch (lastChar) {
    case 'Y':
      term = termPeriodTranslations('year', match[2]);
      break;
    case 'M':
      if (match[1] === '') {
        term = termPeriodTranslations('month', match[2]);
      } else {
        term = termPeriodTranslations('minute', match[2]);
      }
      break;
    case 'W':
      term = termPeriodTranslations('week', match[2]);
      break;
    case 'D':
      term = termPeriodTranslations('day', match[2]);
      break;
    case 'H':
      term = termPeriodTranslations('hour', match[2]);
      break;
    case 'S':
      term = termPeriodTranslations('second', match[2]);
      break;
    default:
      term = '';
      break;
  }
  return term;
};

export const addKeyToRetentionTerm = (term, formatMessage) => {
  let translatedPeriod = '';
  if (term.period) {
    const match = term.period.match(/P(T*)(\d+)\w/);
    translatedPeriod = formatMessage(
      transformRetentionTerm(term.period).props,
      { term: match[2] }
    );
  }
  let key = '';
  if (translatedPeriod && term.offset && term.offset.name) {
    key = `${translatedPeriod} ${term.offset.name}`;
  } else if (translatedPeriod !== '') key = `${translatedPeriod}`;
  else key = term.offset && `${term.offset.name}`;

  const modifiedTerm = { ...term, key, periodKey: translatedPeriod };
  return modifiedTerm;
};

export const getRisksByType = (type, risks, jurisdictions) => {
  // need to include gdpr risks when eu idpr is checked
  const riskJurisdictions = [
    ...jurisdictions,
    ...(jurisdictions.includes('IDPR') ? ['GDPR'] : [])
  ];

  if (risks) {
    let riskList = {
      gdpr: [],
      ccpa: [],
      appi: [],
      pipl: [],
      lgpd: [],
      fdpl: [],
      sgpdpa: [],
      pdp: [],
      ukgdpr: []
    };
    riskJurisdictions.forEach((jurisdiction) => {
      const riskField = jurisdiction.toLowerCase();
      const riskValues = risks[riskField];
      if (typeof type === 'string') {
        riskList = {
          ...riskList,
          [riskField]:
            riskValues?.filter(
              (risk) => risk?.field === type && !risk.isIgnored
            ) || []
        };
      } else {
        riskList = {
          ...riskList,
          [riskField]:
            riskValues?.filter(
              (risk) => type.includes(risk?.field) && !risk.isIgnored
            ) || []
        };
      }
    });
    return riskList;
  }
  return null;
};

export const getComplianceButton = (
  showRisks,
  toggleShowRisks,
  customStyle
) => (
  <span style={{ ...customStyle, float: 'right' }}>
    <div
      style={{ maxHeight: '50px', maxWidth: '50px', display: 'inline-flex' }}
    >
      {!showRisks && (
        <svg width="50px" height="50px">
          <defs>
            <linearGradient id="myGradient" x1="45%" y1="45%" x2="55%" y2="55%">
              <stop offset="50%" stopColor="#00ad55" />
              <stop offset="50%" stopColor="#E03A18" />
            </linearGradient>
          </defs>

          <rect width="45" height="45" fill="url(#myGradient)" rx="4" />
        </svg>
      )}
      <ArrowTooltip
        title={
          showRisks
            ? recordTranslations.hideRiskHint
            : recordTranslations.showRiskHint
        }
      >
        <Button
          className="button_icon_no_margin"
          style={
            showRisks
              ? complianceStyles.noComplianceButton
              : complianceStyles.complianceButton
          }
          onClick={toggleShowRisks}
        >
          <Done style={{ color: 'white' }} />
        </Button>
      </ArrowTooltip>
    </div>
  </span>
);

export const renderHelpNote = (label, id) => (
  <div>
    <ArrowTooltip id={id} title={label}>
      <IconButton style={styles.rightIcon}>
        <ActionHelp color="primary" />
      </IconButton>
    </ArrowTooltip>
  </div>
);

export const getRiskButtonBgColor = (severity) => {
  switch (severity) {
    case 'risk_estimate_type_none':
      return '#00ad55';
    case 'risk_estimate_type_low':
      return '#dbc734';
    case 'risk_estimate_type_medium':
      return 'Orange';
    case 'risk_estimate_type_high':
      return 'Red';
    default:
      return '#00ad55';
  }
};

export const renderRiskEstimateButton = (
  severity,
  index,
  updateDetailsData,
  disabled = false
) => {
  const riskSeverity = [
    'risk_estimate_type_none',
    'risk_estimate_type_low',
    'risk_estimate_type_medium',
    'risk_estimate_type_high'
  ];
  return riskSeverity.map((risk) => (
    <Button
      key={`${risk}-button`}
      classes={{
        root: 'button_risk',
        label: 'buttonLabel_confirm_dialog'
      }}
      variant="text"
      style={
        risk === severity ||
        (severity === undefined && risk === 'risk_estimate_type_none')
          ? { backgroundColor: getRiskButtonBgColor(severity), color: 'white' }
          : { color: 'black' }
      }
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        updateDetailsData(risk, index, 'severity');
      }}
    >
      {getRiskTranslation(risk)}
    </Button>
  ));
};

const riskStyle = {
  width: '24%',
  textAlign: 'center',
  lineHeight: 2.5,
  borderBottom: '5px solid',
  fontSize: '14px'
};

export const renderRiskEstimateIndicator = (
  severity,
  indicatorStyle,
  showSidebar
) => {
  const riskSeverity = [
    'risk_estimate_type_none',
    'risk_estimate_type_low',
    'risk_estimate_type_medium',
    'risk_estimate_type_high'
  ];
  return riskSeverity.map((risk) => (
    <div
      key={`${risk}-indicator`}
      style={
        risk === severity ||
        ((severity === '' || severity === undefined) &&
          risk === 'risk_estimate_type_none')
          ? {
            ...riskStyle,
            borderBottomColor: getRiskButtonBgColor(severity),
            color: getRiskButtonBgColor(severity),
            paddingLeft: showSidebar ? 5 : 0
          }
          : { ...riskStyle, ...indicatorStyle }
      }
    >
      {getRiskTranslation(risk)}
    </div>
  ));
};

export const EEACountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'GI',
  'GP',
  'YT',
  'PM',
  'IE',
  'IT',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'AX',
  'MQ',
  'RE',
  'MC',
  'SM',
  'GF',
  'EN',
  'EU',
  'EUI'
];

export const getIntlChecked = (selectedItems) => {
  let intlChecked = false;
  const filteredData = selectedItems.filter(
    (item) => item.value.country && !EEACountries.includes(item.value.country)
  );
  if (filteredData.size > 0) {
    intlChecked = true;
  }
  return intlChecked;
};

export const getModifiedList = (
  visibleFields,
  source,
  isViewRestrictedUser,
  isPublic
) => {
  const visibleFieldList = {};
  Object.keys(visibleFields).forEach((type) => {
    const fullFieldsList = getFullFieldsListByRecordType(type, isPublic);
    if (isViewRestrictedUser) {
      visibleFieldList[type] = fullFieldsList.map((field) => ({
        parent: field.key,
        child: visibleFields[type][0].fields
          ? visibleFields[type][0].fields.filter((selectedField) =>
            field.subFields.some((subField) => subField.key === selectedField)
          )
          : []
      }));
    } else {
      visibleFieldList[type] = fullFieldsList.map((field) => ({
        parent: field.key,
        child: field.subFields.map((subField) => subField.key)
      }));
    }

    if (source === 'filterMenu' && type !== 'assessment' && !isPublic) {
      visibleFieldList[type].map(
        (item) => item.parent === 'general' && item.child.push('Name')
      );
      visibleFieldList[type].pop();
      if (
        visibleFields[type][0].fields.includes('Attachments') ||
        !isViewRestrictedUser
      ) {
        visibleFieldList[type].push({
          parent: 'attachments',
          child: ['Attachments']
        });
      }
      visibleFieldList[type].push({ parent: 'permissions', child: ['status'] });
    } else if (source === 'filterMenu' && type === 'assessment') {
      visibleFieldList[type].forEach((item) => {
        if (item.parent === 'PreAssessment') item.child.push('Name');
        else if (item.parent === 'permissions' && !isPublic)
          item.child.push('status');
      });
    }
  });

  return visibleFieldList;
};

const getFullFieldsListByRecordType = (type, isPublic) => {
  switch (type) {
    case 'processing':
      return isPublic ? publicProcessingFields : processingFullfieldsList;
    case 'assessment':
      return isPublic ? publicAssessmentFields : assessmentFullfieldsList;
    default:
      return [];
  }
};

export const getFilteredVisibleList = (
  visibleFields,
  isViewRestrictedUser,
  isPublic
) => {
  const visibileFieldList = {};
  Object.keys(visibleFields).forEach((type) => {
    const fields = isViewRestrictedUser
      ? visibleFields[type][0].fields
      : [].concat(
        ...getFullFieldsListByRecordType(type, isPublic)
          .map((item) => item.subFields)
          .map((item) => item.map((c) => c.key))
      );
    visibileFieldList[type] = fields;
  });
  return visibileFieldList;
};

export const getFilteredLinksList = (links, hiddenFieldsList) => {
  const hiddenFields = hiddenFieldsList.map((item) => item.toLowerCase());
  const filteredLinksList = links.filter((el) => {
    const visibleFieldsList = linkGroupItems.filter(
      (item) => !hiddenFields.includes(item.toLowerCase())
    );
    if (
      hiddenFieldsList.includes('InternationalTransfers') &&
      visibleFieldsList.includes('purposesOfTransfer')
    ) {
      visibleFieldsList.splice(
        visibleFieldsList.indexOf('purposesOfTransfer'),
        1
      );
    }
    return visibleFieldsList.some((item) =>
      el.value ? el.value[item].length > 0 : el[item].length > 0
    );
  });
  return filteredLinksList;
};

export const getUpdatedFilterMenu = (layout, key, enable) => {
  const modifiedData = { ...layout };
  const chapters = modifiedData.chapters?.map((menu) =>
    key === menu.value ? { ...menu, enable } : menu
  );
  return { ...modifiedData, chapters };
};

export const getNextChapter = (filterMenu, currentFilter) => {
  let index = -1;
  switch (currentFilter) {
    case 'securityMeasures':
    case 'legalQualifications': {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const legalQualifications = filterMenu[index];
      return legalQualifications.subMenu &&
        legalQualifications.subMenu.length >= 2
        ? legalQualifications.subMenu[1].data
        : filterMenu[index + 1].value;
    }
    case 'SGPDPA':
    case 'LGPD':
    case 'PDP':
    case 'FDPL':
    case 'APPI':
    case 'PDPA':
    case 'PIPL':
    case 'GDPR':
    case 'CCPA':
    case 'UKGDPR': {
      const indexOfLegalQualification = filterMenu.findIndex(
        (item) => item.value === 'legalQualifications'
      );
      const legalQualifications = filterMenu[indexOfLegalQualification];

      const indexOfCurrentFilter = legalQualifications.subMenu.findIndex(
        (item) => item.data === currentFilter
      );
      if (legalQualifications.subMenu.length <= indexOfCurrentFilter + 1) {
        return filterMenu[indexOfLegalQualification + 1].value;
      }

      return legalQualifications.subMenu[indexOfCurrentFilter + 1].data;
    }
    case 'complianceAndRisk': {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const complianceAndRisks = filterMenu[index];
      return complianceAndRisks.subMenu &&
        complianceAndRisks.subMenu.length >= 2
        ? complianceAndRisks.subMenu[1].key
        : filterMenu[index + 1].value;
    }
    case 'gdprComplianceAndRisk':
    case 'pdpaComplianceAndRisk':
    case 'piplComplianceAndRisk':
    case 'appiComplianceAndRisk':
    case 'lgpdComplianceAndRisk':
    case 'pdpComplianceAndRisk':
    case 'fdplComplianceAndRisk':
    case 'sgpdpaComplianceAndRisk':
    case 'ccpaComplianceAndRisk':
    case 'ukgdprComplianceAndRisk': {
      const indexOfComplianceAndRisk = filterMenu.findIndex(
        (item) => item.value === 'complianceAndRisk'
      );
      const complianceAndRisks = filterMenu[indexOfComplianceAndRisk];

      const indexOfCurrentFilter = complianceAndRisks.subMenu.findIndex(
        (item) => item.key === currentFilter
      );
      if (complianceAndRisks.subMenu.length <= indexOfCurrentFilter + 1) {
        return filterMenu[indexOfComplianceAndRisk + 1].value;
      }

      return complianceAndRisks.subMenu[indexOfCurrentFilter + 1].key;
    }
    default:
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      return filterMenu[index + 1].value;
  }
};

export const getPreviousChapter = (filterMenu, currentFilter) => {
  let index = -1;
  switch (currentFilter) {
    case 'APPI':
    case 'LGPD':
    case 'PDP':
    case 'FDPL':
    case 'PDPA':
    case 'CCPA':
    case 'PIPL':
    case 'GDPR':
    case 'SGPDPA':
    case 'UKGDPR': {
      const indexOfLegalQualification = filterMenu.findIndex(
        (item) => item.value === 'legalQualifications'
      );
      const legalQualifications = filterMenu[indexOfLegalQualification];
      const indexOfCurrentFilter = legalQualifications.subMenu.findIndex(
        (item) => item.data === currentFilter
      );

      if (indexOfCurrentFilter === 0) {
        return filterMenu[indexOfLegalQualification - 1].value;
      }

      return filterMenu[indexOfLegalQualification].subMenu[
        indexOfCurrentFilter - 1
      ].data;
    }
    case 'attachments':
    case 'permissions': {
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      const chapterBefore = filterMenu[index - 1];
      if (chapterBefore.subMenu && chapterBefore.subMenu.length > 0) {
        const subMenu = chapterBefore.subMenu[chapterBefore.subMenu.length - 1];
        return currentFilter === 'attachments' ? subMenu.data : subMenu.key;
      }
      return chapterBefore.value;
    }
    case 'gdprComplianceAndRisk':
    case 'pdpaComplianceAndRisk':
    case 'piplComplianceAndRisk':
    case 'appiComplianceAndRisk':
    case 'lgpdComplianceAndRisk':
    case 'pdpComplianceAndRisk':
    case 'fdplComplianceAndRisk':
    case 'sgpdpaComplianceAndRisk':
    case 'ccpaComplianceAndRisk':
    case 'ukgdprComplianceAndRisk': {
      const indexOfComplianceAndRisk = filterMenu.findIndex(
        (item) => item.value === 'complianceAndRisk'
      );
      const complianceAndRisks = filterMenu[indexOfComplianceAndRisk];
      const indexOfCurrentFilter = complianceAndRisks.subMenu.findIndex(
        (item) => item.key === currentFilter
      );

      if (indexOfCurrentFilter === 0) {
        return filterMenu[indexOfComplianceAndRisk - 1].value;
      }

      return filterMenu[indexOfComplianceAndRisk].subMenu[
        indexOfCurrentFilter - 1
      ].key;
    }
    default:
      index = filterMenu.findIndex((item) => item.value === currentFilter);
      return filterMenu[index - 1]?.value;
  }
};

export const transformNumberOfDataSubjects = (numOfData) => {
  let modifiedObj = numOfData && { ...numOfData };
  if (numOfData && numOfData.max.new && numOfData.max.old) {
    modifiedObj = {
      new: {
        min: numOfData && numOfData.min.new,
        max: numOfData && numOfData.max.new
      },
      old: {
        min: numOfData && numOfData.min.old,
        max: numOfData && numOfData.max.old
      }
    };
  }

  return modifiedObj;
};

export const isChangeStatusToDraft = (currentData = {}, userHierarchy) => {
  const statusLists = [
    'record_status_approved',
    'record_status_disapproved',
    'record_status_review_soon',
    'record_status_review_overdue'
  ];
  if (statusLists.includes(currentData.status)) {
    if (![NO_LIMIT, TOP_DOWN].includes(userHierarchy)) {
      return true;
    }
  }
  return false;
};

export const checkForValidSpecificNumber = (
  specificNumberOfDataSubjects,
  numberOfDataSubjects
) => {
  let isValidSpecificNumber = false;
  if (
    specificNumberOfDataSubjects ||
    numberOfDataSubjects === -2 ||
    (numberOfDataSubjects &&
      numberOfDataSubjects.min &&
      numberOfDataSubjects.min === numberOfDataSubjects.max &&
      numberOfDataSubjects.min !== -1)
  )
    isValidSpecificNumber = true;
  return isValidSpecificNumber;
};

export const maxIntegerValue = 2147483647;

export const transformCopyRecordData = (requestData, currentUser, aclOrgs) => {
  const reqData = { ...requestData };
  const userId = currentUser ? currentUser.get('id') : '';
  const filteredUser = requestData.acl.userRights.filter(
    (item) => item.value.subject.id === userId
  );
  const organisation = aclOrgs.length > 1 ? [] : aclOrgs;
  reqData.acl.userRights = filteredUser;
  reqData.companyAccess = false;
  delete reqData.templateInfo;
  return { ...reqData, aclOrgs: organisation };
};

export const updatePersonalInfo = (
  groupsWithPDI,
  fields,
  type,
  currentLegalQualifications,
  jurisdiction,
  property
) => {
  const isUpdaterequired = groupsWithPDI.some((item) =>
    item.personalDataItems.some((pdi) =>
      fields.includes(pdi.value.categoryType)
    )
  );
  let legalQualifications = { ...currentLegalQualifications };
  if (jurisdiction === 'APPI') {
    if (
      isUpdaterequired &&
      !legalQualifications?.personalInformations?.includes(type)
    ) {
      legalQualifications = {
        ...legalQualifications,
        personalInformations: [
          ...legalQualifications.personalInformations,
          type
        ]
      };
      return legalQualifications;
    }
  } else if (
    isUpdaterequired &&
    !currentLegalQualifications?.[type]?.[property]
  ) {
    if (jurisdiction === 'PIPL') {
      legalQualifications = {
        ...legalQualifications,
        [type]: { ...legalQualifications?.[type], [property]: true }
      };
    } else {
      legalQualifications = { ...legalQualifications, [type]: '' };
    }
    return legalQualifications;
  }
  return legalQualifications;
};

export const updateJurisdictionProperties = (
  currentData,
  jurisdiction,
  legalQualifications,
  isIntlProperty,
  isPdiProperty,
  countrycode,
  specialSubProperty
) => {
  const isUkgdpr = jurisdiction === 'UKGDPR';
  let jurisdictionLegalQualification = { ...legalQualifications };
  let totalItems =
    (currentData?.dataRecipients || []).concat(currentData?.processorList) ||
    [];
  if (isUkgdpr) {
    totalItems = totalItems
      .concat(currentData?.controllers)
      .concat(currentData?.executingEntities);
  }
  const isIntlTransfer = totalItems.some(
    (item) =>
      (item.value?.country?.id ||
        item.value?.country ||
        item.country?.id ||
        item.country) !== countrycode
  );
  if (isIntlTransfer) {
    if (jurisdiction === 'PDPA' || isUkgdpr)
      jurisdictionLegalQualification = { [isIntlProperty]: isIntlTransfer };
    if (jurisdiction === 'PIPL' || jurisdiction === 'FDPL')
      jurisdictionLegalQualification = { [isIntlProperty]: {} };
    if (
      jurisdiction === 'PDP' ||
      jurisdiction === 'LGPD' ||
      jurisdiction === 'SGPDPA'
    )
      jurisdictionLegalQualification = { [isIntlProperty]: '' };
  }
  const hasSpecialOrCrimeRelatedPdi = currentData.personalDataItems.some(
    (item) =>
      item.value.categoryType === 'special' ||
      item.value.categoryType === 'crime_related'
  );
  if (
    hasSpecialOrCrimeRelatedPdi &&
    !(legalQualifications && legalQualifications[isPdiProperty])
  ) {
    if (jurisdiction === 'PDPA' || jurisdiction === 'LGPD') {
      jurisdictionLegalQualification = {
        ...jurisdictionLegalQualification,
        [isPdiProperty]: ''
      };
    } else if (jurisdiction === 'PIPL') {
      jurisdictionLegalQualification = {
        ...jurisdictionLegalQualification,
        specialDataType: {
          ...jurisdictionLegalQualification.isPdiProperty,
          [specialSubProperty]: true
        }
      };
    }
  }

  if (isIntlTransfer || hasSpecialOrCrimeRelatedPdi)
    return jurisdictionLegalQualification;
  return null;
};

export const showPermissionSection = (visibleFields) =>
  visibleFields.includes('UserPermissions') ||
  visibleFields.includes('OrganisationPermissions') ||
  visibleFields.includes('CompanyAccess');

export const isLinkVisible = (visibleFields) => {
  const processedData = [
    'DataSubjectCategories',
    'PersonalDataCategories',
    'PersonalDataItems',
    'Purposes',
    'DataSources',
    'RetentionTerms',
    'InternationalTransfers',
    'TechnicalSecurityMeasures',
    'OrganisationalSecurityMeasures'
  ];
  const isLinkItemsVisible =
    visibleFields.filter((x) => processedData.includes(x)).length > 0;
  return isLinkItemsVisible;
};

export const transformResponseData = (items, intl) => {
  const { formatMessage } = intl;
  let modifiedItems = [...items];
  if (modifiedItems) {
    modifiedItems = modifiedItems.map((link) => {
      const retentionTerms = link.retentionTerms.map((term) =>
        addKeyToRetentionTerm(term, formatMessage)
      );
      return {
        ...link,
        retentionTerms
      };
    });
  }
  return modifiedItems;
};

export const getItemNames = (values) => {
  if (values && values.length > 0) {
    const items = values.map((item) => (
      <div
        key={item.value ? item.value.name : item.key}
        style={{ color: '#808080', marginTop: 5 }}
      >
        {item.value ? item.value.name : item.key}
      </div>
    ));
    return items;
  }
  return (
    <div style={{ color: '#808080', marginTop: 8 }}>
      {commonTranslations.notSelected}
    </div>
  );
};

export const getLegalQualificationsSubMenu = (
  menuItems,
  currentJurisdictions
) => {
  let modifiedItems = [...menuItems];
  supportedJurisdictions.forEach((jurisdiction) => {
    modifiedItems = menuItems.filter((item) => {
      if (item.value.toLowerCase() === 'legalqualifications') {
        if (currentJurisdictions.includes(jurisdiction.data)) {
          if (
            item.subMenu.findIndex(
              (subMenu) => subMenu.data === jurisdiction.data
            ) === -1
          ) {
            // if not already available in menu, add
            item.subMenu.push(jurisdiction);
          }
        } else if (
          item.subMenu.findIndex(
            (subMenu) => subMenu.data === jurisdiction.data
          ) !== -1
        ) {
          // if already available in menu, remove
          const newSubmenus = item.subMenu.filter(
            (subMenu) => subMenu.data !== jurisdiction.data
          );
          item.subMenu = [...newSubmenus];
        }
      }
      return item;
    });
  });
  return modifiedItems;
};

export const isReviewDetailsEnabled = (userHierarchy, status) => {
  const hasFlatLimit = userHierarchy === FLAT;
  const reviewDetailsStatuses = [
    'record_status_approved',
    'record_status_review_soon',
    'record_status_review_overdue'
  ];
  const isEnabled = !hasFlatLimit && reviewDetailsStatuses.includes(status);
  return isEnabled;
};

export const getNextReviewMinDate = () => {
  const currentDate = new Date();
  const nextReviewMinDate = new Date(
    currentDate.setDate(currentDate.getDate() + 31)
  ).setHours(0, 0, 0, 0);
  return nextReviewMinDate;
};

export const validateNextReviewDate = (date, _, { locale }) => {
  if (date === '') return errortranslations.required;
  if (new Date(date).setHours(0, 0, 0, 0) >= getNextReviewMinDate())
    return undefined;
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  const nextDate = new Intl.DateTimeFormat(locale, options).format(
    getNextReviewMinDate()
  );
  return recordTranslations.dateNextReviewError(nextDate);
};

export const getDefaultLayoutId = (recordType) =>
  ({
    dpia_assessments: '13b82697-8a07-41cd-a216-3ef8509acf79',
    assessments: '13b82697-8a07-41cd-a216-3ef8509acf79',
    assessment: '13b82697-8a07-41cd-a216-3ef8509acf79',
    processing: '9a75f68f-fbf3-473a-a2de-0dcb5e48ebb7',
    processing_processings: '9a75f68f-fbf3-473a-a2de-0dcb5e48ebb7',
    processings: '9a75f68f-fbf3-473a-a2de-0dcb5e48ebb7',
    tia_assessments: '9e803e46-4931-4026-b6c3-988b88f68ae3',
    tia: '9e803e46-4931-4026-b6c3-988b88f68ae3',
    breach: '6028654d-fcc1-41c6-9661-c9208a5c1932',
    breach_breaches: '6028654d-fcc1-41c6-9661-c9208a5c1932',
    document: '3007301b-be0b-45ba-972d-7dd2e7e30c25'
  }[recordType]);

export const customFieldsEmptyData = {
  text: {},
  htmlText: {},
  checkbox: {},
  simpleMasterData: {},
  picklist: {},
  scoredPicklist: {},
  stakeholder: {}
};

export const getRecordTypeName = (uniqueId) => {
  switch (uniqueId) {
    case 'tia':
    case 'assessment':
    case 'assessments':
      return 'assessment';
    case 'processing':
    case 'processings':
      return 'processing';
    case 'breach':
    case 'breaches':
      return 'breach';
    default:
      return 'custom';
  }
};

export const filterByVisibility = (menuItems, menuVisibility) => {
  // menu visibility transformed to { chapterName: [visibleFields]... }
  const transformedMenuVisibility = {};
  menuVisibility.forEach(({ parent, child }) => {
    transformedMenuVisibility[parent] = child;
  });

  // returning chapters present in menu visibility and have empty visible fields
  return menuItems
    .map((item) => ({
      ...item,
      subMenu: item.subMenu && filterByVisibility(item.subMenu, menuVisibility)
    }))
    .filter(({ value }) =>
      transformedMenuVisibility[value]
        ? !!transformedMenuVisibility[value].length
        : true
    );
};

export const filterMenuItemsByFieldRules = (
  menuItems,
  chapters,
  currentData
) => {
  let filteredMenuItems = [...menuItems];

  chapters?.forEach((chapter) => {
    const { isUsedInRules } = chapter;
    const chapterIndex = filteredMenuItems.findIndex(
      (menuItem) => chapter.key === menuItem.key
    );

    if (isUsedInRules && chapterIndex !== -1) {
      const { hidden, disabled } = getFieldAction(chapter, currentData);

      if (disabled) {
        filteredMenuItems = disableChapters(filteredMenuItems, [chapter.value]);
      }
      if (!disabled) {
        filteredMenuItems = enableChapters(filteredMenuItems, [chapter.value]);
      }
      if (hidden) {
        filteredMenuItems = removeChapters(filteredMenuItems, [chapter.value]);
      }
    }

    // Apply field rules to subMenu if it exists
    const subMenuExists =
      chapter.subMenu && filteredMenuItems[chapterIndex]?.subMenu;

    if (subMenuExists) {
      filteredMenuItems[chapterIndex].subMenu = filterMenuItemsByFieldRules(
        filteredMenuItems[chapterIndex].subMenu,
        chapter.subMenu,
        currentData
      );
    }
  });

  return filteredMenuItems;
};

export const filterDpiaChapters = (
  menuItems,
  assessmentType,
  optionalChapters
) => {
  // visible dpia chapters include the selected optional chapters,
  // full dpia default chapters - groups and processing description
  const isVisibleDpiaChapter = (dpiaOnlyChapter) =>
    assessmentType === 'assessment_full_assessment' &&
    [...optionalChapters, 'processingDescription', 'groups'].includes(
      dpiaOnlyChapter.value
    );
  return menuItems.map((item) => {
    const enable = item.isPreDpia || isVisibleDpiaChapter(item);
    return item.enable !== enable ? { ...item, enable } : item;
  });
};

export const disableChapters = (menuItems, chapters) =>
  menuItems.map((item) => {
    const enable = item.enable && !chapters.includes(item.value);
    const subMenu = item.subMenu
      ? disableChapters(item.subMenu, chapters)
      : null;
    return { ...item, enable, subMenu };
  });

export const enableChapters = (menuItems, chapters) =>
  menuItems.map((item) => {
    const enable = item.enable || chapters.includes(item.value);
    const subMenu = item.subMenu
      ? enableChapters(item.subMenu, chapters)
      : null;
    return { ...item, enable, subMenu };
  });

export const removeChapters = (menuItems, chapters) =>
  menuItems
    .map((item) => ({
      ...item,
      subMenu: item.subMenu && removeChapters(item.subMenu, chapters)
    }))
    .filter((item) => !chapters.includes(item.value));

export const setDefaultChapter = (menuItems) =>
  menuItems.map((item) => ({
    ...item,
    ...(item.subMenu?.length && {
      defaultFilter:
        item.subMenu.find((subItem) => subItem.enable !== false)?.value ||
        item.value
    })
  }));
export const getModeUrl = (mode) =>
  window.location.hash.replace(/edit|view|graph/i, mode).replace(/#/i, '');

export const getLegacyProperty = (name) =>
  ({
    ppPurpose: 'purposes',
    ppProcessingCategories: 'processingCategories',
    ppReferences: 'references',
    ppControllers: 'controllers',
    ppProcessors: 'processors',
    ppExecutingEntities: 'executingEntities',
    ppDataRecipients: 'dataRecipients',
    ppDataSubjectCategories: 'dataSubjectCategories',
    ppPersonalDataCategories: 'personalDataCategories',
    ppDataSourceCategories: 'dataSourceCategories',
    ppTechnicalSecurityMeasures: 'technicalSecurityMeasures',
    ppOrganisationalSecurityMeasures: 'organisationalSecurityMeasures',
    ppContractualSecurityMeasures: 'contractualSecurityMeasures'
  }[name]);

export const hasPromoteRecordPermission = (userPermissions) => {
  const keysToCheck = [
    'createPreDpia',
    'createProcessing',
    'createAssessment',
    'createTia',
    'createBreach',
    'createCustom'
  ];

  // Check if any key has a true value in userPermissions
  return keysToCheck.some((key) => userPermissions.get(key));
};

export const getRecordActionDialogTitle = (recordAction) =>
  recordAction === 'promote'
    ? recordTranslations.promoteRecord
    : recordTranslations.upgradeRecord;

export const getModifiedProcessors = (processors) => {
  if (processors.children)
    return {
      ...processors,
      children: getModifiedProcessors(processors.children)
    };
  else
    return processors.map((item) => ({
      ...item,
      ...(item.value && {
        value: {
          ...item.value,
          ...(!item.value.country.id && { country: { id: item.value.country } })
        }
      })
    }));
};

export const getCreateDialogSubTitle = (
  shouldShowCustomLabel,
  isTemplateMode,
  label
) => {
  const templateModeSubtitle = isTemplateMode ? 'Template' : 'Record';
  if (shouldShowCustomLabel) {
    return customTranslation(
      `createNew${templateModeSubtitle}SubHeader`,
      label
    );
  }
  return isTemplateMode
    ? recordTranslations.createTemplateDefaultLabel
    : recordTranslations.createRecordDefaultLabel;
};

export const getCreateDialogHintTextLabel = (
  shouldShowCustomLabel,
  isTemplateMode
) => {
  if (shouldShowCustomLabel) {
    return customTranslation('nameHint');
  }
  return isTemplateMode
    ? recordTranslations.newTemplateName
    : recordTranslations.newRecordName;
};

export const EEAJurisdictions = ['EU', 'EUI', 'EU_EUI'];

export const EUJurisdictions = ['GDPR', 'IDPR'];

export const getModifiedRecordData = (defaultData, data) => {
  // When creating a record from vendor using template, include the vendor and processors in template as processors
  const vendor = data.processors?.[0];
  return {
    ...defaultData,
    ...data,
    ...(vendor && {
      processors: [
        ...data.processors,
        ...defaultData.processors.filter(
          (item) => item.value.id !== vendor?.value?.id
        )
      ]
    })
  };
};

export const complianceValueOptions = [
  { label: 'True', value: true },
  { label: 'False', value: false }
];

export const setNestedValue = (data, path, value) => {
  const keys = path?.split('.');
  const lastKey = keys.pop();
  let current = data;
  keys.forEach((key) => {
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  });
  current[lastKey] = value;
};

const getFieldValue = (path, currentData) => {
  const pathArray = path.split('.');
  return pathArray.reduce((acc, key) => acc && acc[key], currentData);
};

const getValueOfAttribute = (fieldValue, attribute) => {
  const attributeValue = {
    country:
      getFieldValue(attribute.nestedPath, fieldValue) ||
      getFieldValue('value.country', fieldValue),
    dataStorageCountry:
      getFieldValue(attribute.nestedPath, fieldValue) ||
      getFieldValue('value.dataStorageCountry', fieldValue),
    organisationCountry:
      getFieldValue(attribute.nestedPath, fieldValue) ||
      getFieldValue('value.organisationData.country.id', fieldValue),
    dependentCountry:
      getFieldValue(attribute.nestedPath, fieldValue) ||
      getFieldValue('value.dataStorageCountry', fieldValue) ||
      getFieldValue('value.organisationData.country.id', fieldValue),
    organisation:
      getFieldValue('value.organisation.key', fieldValue) ||
      getFieldValue('value.organisation', fieldValue)
  };
  return (
    attributeValue[attribute.attributeId] ||
    getFieldValue(attribute.nestedPath, fieldValue)
  );
};

const isValueIncludedInAttributeValue = (fieldValue, attribute, values) => {
  if (!fieldValue || !values) return false;
  return (
    fieldValue &&
    fieldValue.length > 0 &&
    fieldValue.some(
      (fieldValue) =>
        values.length > 0 &&
        values.some((value) => {
          const attributeValue = getValueOfAttribute(fieldValue, attribute);
          if (!attributeValue) return false;

          // Special handling for country attribute when country group is specified
          const EEACountryList = [...EUCountryList, ...EEACountries];
          if (attribute.attributeType === 'country') {
            if (value.id === 'EU')
              return [...EUCountryList, 'EU'].includes(attributeValue);
            if (value.id === 'EN')
              return EEACountryList.includes(attributeValue);
            if (value.id === 'OE')
              return !EEACountryList.includes(attributeValue);
          }

          // Check key for organisations, as id (organisation name) can be same
          if (attribute.attributeType === 'organisation')
            return attributeValue === (value.key || value);

          return attributeValue === (value.id || value);
        })
    )
  );
};

const isValueIncludedInAllAttributeValue = (fieldValue, attribute, values) => {
  if (!fieldValue || !values) return false;
  return (
    fieldValue &&
    fieldValue.length > 0 &&
    fieldValue.every(
      (fieldValue) =>
        values.length > 0 &&
        values.some((value) => {
          const attributeValue = getValueOfAttribute(fieldValue, attribute);
          if (!attributeValue) return false;

          // Special handling for country attribute when country group is specified
          const EEACountryList = [...EUCountryList, ...EEACountries];
          if (attribute.attributeType === 'country') {
            if (value.id === 'EU')
              return [...EUCountryList, 'EU'].includes(attributeValue);
            if (value.id === 'EN')
              return EEACountryList.includes(attributeValue);
            if (value.id === 'OE')
              return !EEACountryList.includes(attributeValue);
          }

          // Check key for organisations, as id (organisation name) can be same
          if (attribute.attributeType === 'organisation')
            return attributeValue === (value.key || value);

          return attributeValue === (value.id || value);
        })
    )
  );
};

const isValueIncludedInFieldValue = (fieldValue, fieldType, values) => {
  switch (fieldType) {
    case 'picklist':
    case 'scoredPicklist':
    case 'systemPicklist':
      return (
        fieldValue &&
        values.some(
          (value) =>
            (fieldValue.value?.id || fieldValue?.id || fieldValue) ===
            (value.id || value)
        )
      );
    case 'jurisdictions':
    case 'systemChecklist':
      return (
        fieldValue.length > 0 &&
        fieldValue.some(
          (fieldValue) =>
            fieldValue && values.some((value) => fieldValue === value)
        )
      );
    case 'transferGround':
    case 'processingGround':
      return (
        fieldValue.length > 0 &&
        fieldValue.some(
          (fieldValue) =>
            fieldValue &&
            values.some(
              (value) =>
                `${
                  fieldValue.value.jurisdiction.id ||
                  fieldValue.value.jurisdiction
                } ${fieldValue.value.lawAbbreviation} ${
                  fieldValue.value.article
                }` === value.key
            )
        )
      );
    case 'country':
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.some((fieldValue) =>
          values.some((value) => {
            const fieldValueKey = fieldValue.value?.countryCode;

            // Special handling for country attribute when country group is specified
            const EEACountryList = [...EUCountryList, ...EEACountries];
            if (value.id === 'EU')
              return [...EUCountryList, 'EU'].includes(fieldValueKey);
            if (value.id === 'EN')
              return EEACountryList.includes(fieldValueKey);
            if (value.id === 'OE')
              return !EEACountryList.includes(fieldValueKey);
            return fieldValueKey === (value.id || value);
          })
        )
      );
    case 'compliance':
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.some((fieldValue) => fieldValue.value === values[0])
      );
    default:
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.some((fieldValue) =>
          values.some((value) => {
            const fieldValueKey =
              fieldValue.value?.name || fieldValue.value?.key;
            return fieldValueKey === (value.key || value);
          })
        )
      );
  }
};

const isValueIncludedInAllFieldValue = (fieldValue, fieldType, values) => {
  switch (fieldType) {
    case 'picklist':
    case 'scoredPicklist':
    case 'systemPicklist':
      return (
        fieldValue &&
        values.some(
          (value) =>
            (fieldValue.value?.id || fieldValue?.id || fieldValue) ===
            (value.id || value)
        )
      );
    case 'jurisdictions':
    case 'systemChecklist':
    case 'personalInfoTypes':
      return (
        fieldValue.length > 0 &&
        fieldValue.every(
          (fieldValue) =>
            fieldValue && values.some((value) => fieldValue === value)
        )
      );
    case 'transferGround':
    case 'processingGround':
      return (
        fieldValue.length > 0 &&
        fieldValue.every(
          (fieldValue) =>
            fieldValue &&
            values.some(
              (value) =>
                `${
                  fieldValue.value.jurisdiction.id ||
                  fieldValue.value.jurisdiction
                } ${fieldValue.value.lawAbbreviation} ${
                  fieldValue.value.article
                }` === value.key
            )
        )
      );
    case 'country':
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.every((fieldValue) =>
          values.some((value) => {
            const fieldValueKey = fieldValue.value?.countryCode;

            // Special handling for country attribute when country group is specified
            const EEACountryList = [...EUCountryList, ...EEACountries];
            if (value.id === 'EU')
              return [...EUCountryList, 'EU'].includes(fieldValueKey);
            if (value.id === 'EN')
              return EEACountryList.includes(fieldValueKey);
            if (value.id === 'OE')
              return !EEACountryList.includes(fieldValueKey);
            return fieldValueKey === (value.id || value);
          })
        )
      );
    case 'compliance':
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.every((fieldValue) => fieldValue.value === values[0])
      );
    default:
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.every((fieldValue) =>
          values.some((value) => {
            const fieldValueKey =
              fieldValue.value?.name || fieldValue.value?.key;
            return fieldValueKey === (value.key || value);
          })
        )
      );
  }
};

const conditionCheckerFunctions = {
  IS_BLANK: ({ fieldValue }) => fieldValue && fieldValue.length === 0,
  IS_EMPTY: ({ fieldValue, attribute }) => {
    if (attribute) {
      // attribute is_empty check
      if (!fieldValue) return false;
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.some((fieldValue) => {
          const attributeValue = getValueOfAttribute(fieldValue, attribute);
          if (!attributeValue) return true;
          return false;
        })
      );
    }
    return fieldValue
      ? fieldValue.length === 0 || fieldValue.children?.length === 0
      : true;
  },
  NOT_EMPTY: ({ fieldValue, fieldType, attribute }) => {
    if (attribute) {
      // attribute not_empty check
      if (!fieldValue) return false;
      return (
        fieldValue &&
        fieldValue.length > 0 &&
        fieldValue.some((fieldValue) => {
          const attributeValue = getValueOfAttribute(fieldValue, attribute);
          if (attributeValue) return true;
          return false;
        })
      );
    }
    if (fieldType === 'stakeholder')
      return (
        fieldValue && (fieldValue.length > 0 || fieldValue.children?.length > 0)
      );
    return (
      fieldValue &&
      (fieldValue.length > 0 || Object.keys(fieldValue).length > 0)
    );
  },
  UNCHECKED: ({ fieldValue }) =>
    // in case of custom checkbox the fieldValue will be [] when UNCHECKED
    (!fieldValue && fieldValue !== '') ||
    (Array.isArray(fieldValue) && fieldValue.length === 0),
  IS_CHECKED: ({ fieldValue }) =>
    // in case of custom checkbox the fieldValue will be ['id'] when CHECKED
    Array.isArray(fieldValue)
      ? fieldValue.length > 0
      : !!fieldValue || fieldValue === '',
  INCLUDES: ({ fieldValue, fieldType, values, attribute }) => {
    if (!fieldValue) return false;
    if (attribute) {
      return isValueIncludedInAttributeValue(fieldValue, attribute, values);
    }
    return isValueIncludedInFieldValue(fieldValue, fieldType, values);
  },

  NOT_INCLUDES: ({ fieldValue, fieldType, values, attribute }) => {
    if (!fieldValue) return true;
    if (attribute) {
      return !isValueIncludedInAttributeValue(fieldValue, attribute, values);
    }
    return !isValueIncludedInFieldValue(fieldValue, fieldType, values);
  },

  ALL_INCLUDES: ({ fieldValue, fieldType, values, attribute }) => {
    if (!fieldValue) return false;
    if (attribute) {
      return isValueIncludedInAllAttributeValue(fieldValue, attribute, values);
    }
    return isValueIncludedInAllFieldValue(fieldValue, fieldType, values);
  },

  ANY_NOT_INCLUDES: ({ fieldValue, fieldType, values, attribute }) => {
    if (!fieldValue) return true;
    if (attribute) {
      return !isValueIncludedInAllAttributeValue(fieldValue, attribute, values);
    }
    return !isValueIncludedInAllFieldValue(fieldValue, fieldType, values);
  }
};

const isCustomField = (path) =>
  [
    'customCheckBoxData.',
    'customHTMLTextFields.',
    'customPicklistData.',
    'customScoredPicklistData.',
    'customSimpleMasterDatas.',
    'customSimpleMasterData.',
    'customStakeholderData.',
    'customTextFields.'
  ].some((item) => path.includes(item));

const checkCondition = (condition, currentData) => {
  const { field, operator, values, attribute } = condition;
  const { path, fieldType, layoutFieldUniqueId } = field;
  const fieldValue = isCustomField(path)
    ? getFieldValue(`${fieldType}.${layoutFieldUniqueId}`, currentData)
    : getFieldValue(path, currentData);

  const checkerFunction = conditionCheckerFunctions[operator];
  if (checkerFunction) {
    return checkerFunction({ fieldValue, fieldType, values, attribute });
  } else {
    return false;
  }
};

const renderDefaultFailAction = (actions) => ({
  hidden: actions.includes('SHOW'),
  disabled: actions.includes('UNLOCK')
});

export const getFieldAction = (field, currentData) => {
  const { fieldRules } = field;

  // If no rules exist, return default (visible and enabled)
  if (!fieldRules || fieldRules.length === 0) {
    return { hidden: false, disabled: false };
  }

  const possibleActions = [];
  const triggeredActions = [];

  fieldRules.forEach((rule) => {
    const { triggerRule, action } = rule;
    possibleActions.push(action);

    // Check if all conditions in each trigger are met
    triggerRule?.triggers.forEach((triggers) => {
      const allConditionsTrue = triggers.trigger.every((trigger) =>
        checkCondition(trigger, currentData)
      );

      // If all conditions are met, add the action to triggered actions
      if (allConditionsTrue) {
        triggeredActions.push(action);
      }
    });
  });

  // Determine visibility and lock/unlock state based on triggered actions
  const shouldShow = triggeredActions.includes('SHOW');
  const shouldLock = triggeredActions.includes('LOCK');
  const shouldUnlock = triggeredActions.includes('UNLOCK');

  if (shouldShow || shouldLock || shouldUnlock) {
    return {
      hidden: false,
      disabled: shouldLock && !shouldUnlock
    };
  }

  // If any action was triggered that hides the field and none to show it
  if (triggeredActions.length > 0) {
    return { hidden: true, disabled: false };
  }

  // Default fallback if no triggers are true
  return renderDefaultFailAction(possibleActions);
};

export const getCustomRisksInField = ({
  field,
  currentData,
  updateProperty,
  isProcessing
}) => {
  const { riskComplianceRules } = field;

  if (!riskComplianceRules || riskComplianceRules.length === 0) {
    return [];
  }

  const possibleRisks = [];
  const triggeredRisks = [];

  riskComplianceRules.forEach((rule) => {
    const { triggerRule, riskComplianceInfo, ruleId } = rule;
    possibleRisks.push({ riskComplianceInfo, ruleId });

    if (triggerRule?.triggers) {
      const riskTriggered = triggerRule.triggers.some((triggers) =>
        triggers.trigger.every((trigger) =>
          checkCondition(trigger, currentData)
        )
      );

      if (riskTriggered) {
        triggeredRisks.push({ riskComplianceInfo, ruleId });
      } else if (updateProperty) {
        // Check all jurisdictions in currentData.risks
        const { risks } = currentData;
        Object.keys(currentData.risks).forEach((jurisdiction) => {
          const jurisdictionRisks = risks[jurisdiction];
          if (
            riskComplianceInfo.applicableJurisdictions.includes(
              jurisdiction.toUpperCase()
            )
          ) {
            // Find the index of the ruleId in the risks array
            const riskIndex = jurisdictionRisks.findIndex(
              (risk) => risk.ruleId === ruleId
            );

            // If the ruleId exists in the risks array, remove it
            if (riskIndex !== -1) {
              jurisdictionRisks.splice(riskIndex, 1);
              if (isProcessing) {
                updateProperty({
                  risks: {
                    ...risks,
                    [`${jurisdiction}`]: jurisdictionRisks
                  }
                });
              } else {
                updateProperty('risks', {
                  ...risks,
                  [`${jurisdiction}`]: jurisdictionRisks
                });
              }
            }
          }
        });
      }
    }
  });

  return triggeredRisks;
};
